import { render } from "./insurance.vue?vue&type=template&id=7b9e905e&scoped=true"
import script from "./insurance.vue?vue&type=script&lang=js"
export * from "./insurance.vue?vue&type=script&lang=js"

import "./insurance.vue?vue&type=style&index=0&id=7b9e905e&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7b9e905e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7b9e905e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7b9e905e', script)) {
    api.reload('7b9e905e', script)
  }
  
  module.hot.accept("./insurance.vue?vue&type=template&id=7b9e905e&scoped=true", () => {
    api.rerender('7b9e905e', render)
  })

}

script.__file = "src/views/static/insurance/insurance.vue"

export default script