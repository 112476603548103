<template>
    <div class="insurance">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    医护到家-保险说明
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <van-tabs type="card" color='#1cc6a3' class='content' >
            <van-tab title="意外险">
                <div class="services_block">
                        <h3> 保险说明</h3>
                        <div class="ser_txt">
                            <div class="services_mg">一、意外伤残和身故险</div>
                            <p>1.【保险对象】:年龄在16周岁至65周岁(含)的医护到家平台提供上门服务的护士</p>
                            <p>2.【保险受益人】：护士</p>

                            <div class="services_mg">【保险期间】</div>
                            <p>被保险人网上订单服务时间起，至服务订单结束时间止。订单起始时间最早不得超出当日凌晨零时，最晚不得晚于当日的二十四时。</p>
                            <div class="services_mg">【保险项目】</div>
                        <div class="ser_txt">
                            <table width="100%" cellspacing="0" cellpadding="0" border="1">
                                <tbody><tr>
                                    <td><span class="span_black">保险项目</span></td>
                                    <td>保险金额</td>
                                    <td>保费</td>
                                </tr>
                                <tr>
                                    <td>上门服务医护人员意外身故、残疾</td>
                                    <td>300,000</td>
                                    <td>医护到家承担</td>
                                </tr>
                                <tr>
                                    <td>被服务人员意外伤害身故、残疾 </td>
                                    <td>300,000</td>
                                    <td>医护到家承担</td>
                                </tr>
                                </tbody></table>
                        </div>
                            <p>1.保险对象: 年龄在16周岁至65周岁(含)的医护到家平台提供上门服务的护士</p>
                <p>2.保险受益人：年龄在16周岁至65周岁(含)的医护到家平台提供上门服务的护士</p>
                <p>3.保险内容:服务地址内遭受的意外伤害,包含意外身残,意外身故；</p>
                <p>意外伤害是指以外来的、突发的、非本意的、非疾病的客观事件为直接且单独的原因致使身体受到的伤害。</p>
                <p>a）在服务过程中包含上门服务的路程中，意外刮伤、意外车祸、意外烫伤等外来的、突发的、非疾病的的事件）所造成的死亡、伤残或身体受伤所产生的医疗费用。</p>
                <p>b）在服务过程中，不小心滑倒、摔倒、磕伤、碰伤、烫伤、烧伤导致死亡或残疾的情况。</p>
                <p>c）在服务过程中，遭遇地震、火灾、爆炸，导致死亡或残疾的情况。</p>
                <p>d）在服务地址内且在服务过程中,遭受的客户的殴打，拘禁，性侵，迫害等情况造成的身残或身故。</p>
                <p>e）其他意外情况，由保险公司根据具体情况进行调查评估而定。</p>
                <p>4.赔付金额:</p>
                <p>a）意外导致的身故和严重身残赔付300000元。 </p>
                <p>b）其他具体情况由保险公司进行评定后保险金额范围为0-300000元。</p>


                    <div class="services_mg">【保险责任】</div>
                <p>在本保险合同的保险期间内，被保险人遭受意外伤害，保险人按下列约定给付保险金。</p>
                <p>本保险合同所称意外伤害，指以外来的、突发的、非本意的和非疾病的客观事件为直接且单独的原因致使身体受到的伤害。</p>
                <p>被保险人自意外伤害发生之日起 180 日内以该次意外伤害为直接原因身故的，保险人按保险单所载该被保险人意外伤害保险金额给付身故保险金。</p>
                <div class="services_mg">一、身故保险责任</div>
                <p>被保险人自意外伤害发生之日起 180 日内以该次意外伤害为直接原因身故的，保险人按保险单所载该被保险人意外伤害保险金额给付身故保险金。</p>
                <p>被保险人因遭受意外伤害事故且自该事故发生日起下落不明，后经中华人民共和国法院宣告死亡的，保险人按保险金额给付身故保险金。但若被保险人被宣告死亡后生还的，保险金申请人应于知道或应当知道被保险人生还后 30 日内退还保险人给付的身故保险金。</p>
                <p>被保险人身故或被宣告死亡前保险人已给付本条款约定的残疾保险金的，身故保险金应扣除已给付的保险金。</p>
                <div class="services_mg">二、残疾保险责任</div>
                <p>被保险人自意外伤害发生之日起 180 日内以该次意外伤害为直接原因致本保险合同所附《人身保险伤残评定标准》（中保协发〔2013〕88 号）所列残疾之一的，保险人按本保险合同所载的该被保险人意外伤害保险金额及该项残疾所对应的给付比例给付残疾保险金。如自意外伤害发生之日起第 180 日时治疗仍未结束，按第 180 日的身体情况进行鉴定，并据此给付残疾保险金。如被保险人的残疾程度不在所附《人身保险伤残评定标准》之列，保险人不承担给付残疾保险金责任。如被保险人的残疾程度不在所附《人身保险伤残评定标准》之列，保险人不承担给付残疾保险金责任。</p>
                <p>当同一保险事故造成两处或两处以上伤残时，应首先对各处伤残程度分别进行评定，如果几处伤残等级不同，以最重的伤残等级作为最终的评定结论；如果两处或两处以上伤残等级相同，伤残等级在原评定基础上最多晋升一级，最高晋升至第一级。同一部位和性质的伤残，不应采用该标准条文两条以上或者同一条文两次以上进行评定。</p>
                <p>对于不同保险事故造成的伤残，本次保险事故导致的伤残合并前次伤残可领较严重等级伤残保险金者，按较严重等级标准给付，但前次已给付的伤残保险金（投保前已患或因责任免除事项所致附件所列的伤残视为已给付伤残保险金）应予以扣除。</p>
                <div class="services_mg">三、保险人对被保险人所负身故、残疾保险金给付责任以保险单所载被保险人的意外伤害保险金额为限，一次或累计给付的保险金达到意外伤害保险金额时，保险人对被保险人的保险责任终止。</div>
                <div class="services_mg">【理赔流程】</div>
                <p>在保险合同生效期间不幸发生保险事故，请在24小时内通过热线电话4008006996报案，并尽可能收集并保存与事故发生及损失有关的资料、影像及发票凭证。我们将在接到您报案后的 1 个工作日内与您联系，指导并协助您办理理赔事宜。请您在出险报案后保持联系电话畅通，以便享受到我司优质的理赔服务。</p>
                <div class="services_mg">【主要责任免除】</div>
                <div class="services_mg">一、因下列原因之一，直接或间接造成被保险人身故、残疾的，保险人不负任何给付保险金责任：</div>
                <p>（一）投保人对被保险人的故意杀害或伤害；</p>
                <p>（二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外；</p>
                <p>（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；</p>
                <p>（四）被保险人违法、犯罪或者抗拒依法采取的刑事强制措施；</p>
                <p>（五）被保险人因疾病导致的伤害，包括但不限于猝死、食物中毒、高原反应、中暑、病毒和细菌感染（意外伤害导致的伤口感染不在此限）；</p>
                <p>（六）被保险人因妊娠、流产、分娩导致的伤害，但意外伤害所致的流产或分娩不在此限；</p>
                <p>（七）被保险人因药物过敏、整容手术、内外科手术或其他医疗行为导致的伤害；</p>
                <p>（八）被保险人未遵医嘱私自服用、涂用、注射药物；</p>
                <p>（九）被保险人因意外伤害、自然灾害事故以外的原因失踪而被法院宣告死亡的；</p>
                <p>（十）被保险人从事高风险运动、参加任何职业或半职业体育运动期间；</p>
                <p>（十一）任何生物、化学、原子能武器，原子能或核能装置所造成的爆炸、灼伤、污染或辐射；</p>
                <p>（十二）恐怖袭击。</p>
                <div class="services_mg">二、下列任一情形下，保险人对被保险人身故、残疾不负任何给付保险金责任：</div>
                <p>（一）被保险人精神失常或精神错乱期间；</p>
                <p>（二）战争、军事行动、暴动或武装叛乱期间；</p>
                <p>（三）被保险人醉酒或受毒品、管制药物的影响期间；</p>
                <p>（四）被保险人酒后驾车、无有效驾驶证驾驶或驾驶无有效行驶证的机动车期间。</p>
                    </div>
                </div>
            </van-tab>
            <van-tab title="第三者责任险">
                 <div class="services_block">
                     <h3> 保险说明</h3>
                    <div class="ser_txt">
                        <div class="services_mg">1.【保险对象】：上门服务的护士</div>
                        <div class="services_mg">2.【保险受益人】：患者</div>
                        <div class="services_mg">3.【保险内容】：</div>
                <p>a）保险期指自被保险人网上订单服务时间起，至服务订单结束时间止。订单起始时间最早不得超出当日凌晨零时，最晚不得晚于当日的二十四时。</p>
                <p>b)在保险期间内，被保险人本人于服务场所内因其疏忽或过失致使第三者遭受人身伤害或财产损失，依事故发生地法律规定应由被保险人承担的经济赔偿责任，保险人根据本保险合同的约定负责赔偿。</p>
                <p>c）被保险人因保险事故而被提起仲裁或者诉讼的，事先经保险人书面同意、应由被保险人支付的必要的、合理的法律费用（以下简称“法律费用”），保险人按照本保险合同约定也负责赔偿。</p>
                <div class="services_mg">4.【保险金额】：</div>
                <p>累计赔偿限额5万元，每次事故赔偿限额5万元其中每次财产损失赔偿限额2万元/单，每次人伤损失赔偿限额3万元/单 。</p>
                <table width="100%" cellspacing="0" cellpadding="0" border="1">
                                <tbody><tr>
                                    <td><span class="span_black">第三者人身伤害＋第三者财产损失（免赔额200元）</span></td>
                                    <td>50，000元（其中第三者财产损失最高赔付上限20，000元,每次人伤最高赔付30,000元）</td>

                                </tr>
                                </tbody>
                </table>
                <div class="services_mg">【特别约定】：</div>
                <p>1、该产品方案仅适用承保通过“医护到家”网络平台接单的医护人员和在“医护到家”网络平台 实名注册并使用服务的用户,在订单服务过程中发生的意外伤害事故及个人责任事故; </p>
                <p>2、本保险只承担年龄在18-65周岁被保险人的保险责任；</p>
                <p>3、第三者责任保险条款中造成第三者财产损失最高赔付限额2万元，免赔额200元；三者财产损失中，单件物品损坏最高800元，赔付比例80%，，易碎品赔付比例50%；</p>
                <p>4、特约家财损失除外责任：金银、珠宝首饰或饰物、移动电话（或称手机、蜂窝电话）、手提电脑（或称手提便携式电脑、笔记本电脑）或个人商务助理设备（或称掌上电脑、PDA），图章、文件，用于商业活动的物品或样品，动物、植物或食物，机动车辆（及其附件）、摩托车、船、发动机或其他运输工具，家具、古董、字画等无法确定价值的物品，被保险人从他人租赁的设备，眼镜、隐形眼镜、助听器和假肢的遗失或损坏。</p>
                <div class="services_mg">【理赔流程】</div>
                <p>在保险合同生效期间不幸发生保险事故，请在24小时内通过热线电话4008006996报案，并尽可能收集并保存与事故发生及损失有关的资料、影像及发票凭证。我们将在接到您报案后的 1 个工作日内与您联系，指导并协助您办理理赔事宜。请您在出险报案后保持联系电话畅通，以便享受到我司优质的理赔服务。</p>

                <div class="services_mg">【主要责任免除】</div>
                <div class="services_mg">1、因下列原因造成被保险人身故、伤残或医疗费用支出的，保险人不承担给付保险金责任：</div>
                <p>（一）投保人的故意行为；</p>
                <p>（二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外；</p>
                <p>（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；</p>
                <p>（四）被保险人妊娠、流产、分娩、疾病、药物过敏、中暑、猝死； </p>
                <p>（五）被保险人接受整容手术及其他内、外科手术；</p>
                <p>（六）被保险人未遵医嘱，私自服用、涂用、注射药物；</p>
                <p>（七）核爆炸、核辐射或核污染；</p>
                <p>（八）被保险人犯罪或拒捕；</p>
                <p>（九）被保险人从事高风险运动或参加职业或半职业体育运动。</p>
                <div class="services_mg">2、被保险人在下列期间遭受伤害导致身故、伤残或医疗费用支出的，保险人也不承担给付保险金责任：</div>
                <p>（一）战争、军事行动、暴动或武装叛乱期间； </p>
                <p>（二）被保险人醉酒或毒品、管制药物的影响期间；</p>
                <p>（三）被保险人酒后驾车、无有效驾驶证驾驶或驾驶无 有效行驶证的机动车期间。</p>
                <div class="services_mg">3、下列费用,保险人不负给付保险金责任: </div>
                <p>（一）保险单签发地社会医疗保险或其他公费医疗管理 部门规定的自费项目和药品费用；</p>
                <p>（二）因椎间盘膨出和突出造成被保险人支出的医疗费用;</p>
                <p>（三）营养费、康 复费、辅助器 具费、整容费 、美容费、 修复手术费、 牙齿整形费、 牙齿修复 费 、 镶 牙费、护理费、交通费、伙食费、误工费、丧葬 费。除上述规定的责任免除事项保险人不予赔偿外，对于下列损失、费用或责任，保险人也不予赔偿：</p>
                <p>（一）任何性质的间接损失；</p>
                <p>（二）被保险人因合同或协议所承担的赔偿责任。但即使无该项合同或协议存在时依法仍应由被保险人负赔偿责任的除外；</p>
                <p>（三）被保险人对租赁、代人保管、管理或控制的财物负有的损失赔偿责任；</p>
                <p>（四）被保险人因从事商业或与其职业相关事务导致的赔偿责任；</p>
                <p>（五）被保险人因所有或使用或管理飞机、船舶及依法应领有牌照的车辆导致的赔偿责任；</p>
                <p>（六）被保险人违反公共场所的管理规定进入或滞留公共场所期间产生的赔偿责任；</p>
                <p>（七）在同一份保险合同中的各个被保险人之间，及在一份保险合同中的投保人和被保险人之间的赔偿责任；</p>
                <p>（八）在共同预定了旅行并一起出行的人之间的赔偿责任；</p>
                <p>（九）由于使用任何武器导致的赔偿责任；</p>
                <p>（十）被保险人的故意行为；</p>
                <p>（十一）由于对土地，空气或者水（包括水域）的环境影响导致的赔偿责任及由此导致的其它所有赔偿责任；</p>
                <p>（十二）因各种传染疾病所导致的赔偿责任；</p>
                <p>（十三）被保险人从事竞技、比赛、特技表演所导致的赔偿责任；</p>
                <p>（十四）精神损害赔偿、罚款、罚金或惩罚性赔偿；</p>
                <p>（十五）保险单上载明的应由被保险人自行承担的免赔额（率）。</p>
                <div class="services_mg">【理赔材料】</div>
                <p>1、理赔申请书；</p>
                <p>2、被保险人和受害人的身份证明；</p>
                <p>3、医护到家平台相关订单信息；</p>
                <p>4、造成受害人人身伤害的,应包括:受害人的病历、诊断证明、医疗费等医疗原始单据;受害人 的人身伤害程度证明:受害人伤残的,应当提供具备相关法律法规要求的伤残鉴定资格的医疗机构 出具的伤残程度证明;受害人死亡的,公安机关或医疗机构出具的死亡证明书;；</p>
                <p>5、事故证明；</p>
                <p>6、被保险人与受害人所签订的赔偿协议书或和解书;经判决或仲裁的,应提供判决文书或仲裁裁 决文书；</p>
                <p>7、保险金申请人所能提供的与确认保险事故的性质、原因、伤害程度等有关的其他证明和材料 。</p>
                </div>

                </div>
            </van-tab>
            <van-tab title="护责险">
                <div class="services_block">
                <h3>保险说明</h3>

                <div class="ser_txt">
                <div class="services_mg">1. 【保险对象】: 基于“医护到家服务平台”所提供上门护理服务的护士，服务项目以该平台上所列项目为准。 </div>
                <div class="services_mg">2. 【保险受益人】:平台护士+被服务的患者 </div>
                <div class="services_mg">3. 【承保区域】:中华人民共和国(不含港、澳、台) </div>
                <div class="services_mg">4. 【保险期间】：被保险人网上订单服务时间起，至服务订单结束时间止。订单起始时间最早不得超出当日凌晨零时，最晚不得晚于当日的二十四时。 </div>
                <div class="services_mg">5. 【保险内容】: </div>
                <p>由于被保险人(护士)提供的保险单载明的专业服务存在过失、错误或遗漏，或被保险人因过失未能提供专业服务，造成委托人或其利害关系人的损失，委托人或其利害关系人在保险期间内首次向被保险人提出经济损失赔偿请求的，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。但保险人承担责任的前提条件是该引致赔偿请求的专业服务是（或应当是）：</p>
                <p>1． 根据被保险人与委托人在保险期间或追溯期内签订的业务合同而提供； </p>
                <p>2． 在保险单载明的保险期间或追溯期内完成；</p>
                <p>3． 在保险单载明的地域范围内提供。</p>
                <div class="services_mg">6. 【赔付金额】:</div>
                <div class="ser_txt">
                            <table width="100%" cellspacing="0" cellpadding="0" border="1">
                                <tbody><tr>
                                    <td><span class="span_black">保险内容</span></td>
                                    <td><span class="span_black">保险金额</span></td>
                                    <td><span class="span_black">免赔</span></td>
                                </tr>
                                <tr>
                                    <td>职业责任险</td>
                                    <td>累计赔偿限额100万元<br>每人每次事故30万元</td>
                                    <td>每次事故绝对免赔额1万元</td>
                                </tr>
                                </tbody></table>
                        </div>

                <div class="services_mg">7. 【除外责任】: </div>
                <p>（一）被保险人从事的业务超出其执业许可证或营业执照核定的范围；</p>
                <p>（二）被保险人超越委托人的授权范围所办理的业务； </p>
                <p>（三）被保险人在执业许可证、营业执照被注销、撤回、吊销期间，或其雇员在被取消执业资格或受停业、停职处分的情况下承办的业务； </p>
                <p>（四）被保险人的雇员未经被保险人同意私自承办的业务，或在受被保险人雇佣之前所承办的业务； </p>
                <p>（五）被保险人从事的非保险单载明的业务；</p>
                <p>（六）在保险单规定的追溯期起始日之前被保险人已经完成的专业服务。</p>
                <div class="services_mg">第六条  下列原因造成的损失、费用和责任，保险人不负责赔偿： </div>
                <p>（一）投保人、被保险人及其代表、雇员的故意行为、犯罪行为、重大过失、欺诈或不诚实行为、恶意串通损害委托人或其利害关系人利益的行为；</p>
                <p>（二）战争、敌对行动、军事行为、武装冲突、罢工、骚乱、暴动、恐怖活动； </p>
                <p>（三）核辐射、核爆炸、核污染及其他放射性污染； </p>
                <p>（四）大气污染、土地污染、水污染及其他各种污染；</p>
                <p>（五）行政行为或司法行为；</p>
                <p>（六）地震、火山爆发、海啸、雷击、洪水、暴雨、台风、龙卷风、暴风、雪灾、雹灾、冰凌、泥石流、崖崩、地崩、突发性滑坡、地面突然下陷等自然灾害；</p>
                <p>（七）委托人提供的账册、报表、文档、数据、资料、电子数据及其他性质类似文件的损毁、灭失、遭盗窃或抢劫、丢失；</p>
                <p>（八）被保险人违反书面或口头约定的保密义务；</p>
                <p>（九）被保险人泄露或不正当使用他人商业秘密，或侵犯他人知识产权、名誉权、隐私权；</p>
                <p>（十）被保险人的明示保证，但无该保证时仍然应由被保险人承担的赔偿责任不受此限；</p>
                <p>（十一）被保险人进行的任何担保。</p>

                <div class="services_mg">8. 【理赔流程】：</div>
                <p>Step 1:及时联系医护到家平台400-800-6996,说明情况,平台了解了详细情况后,如符合索赔条件,平台会立即向对应保险或代理机构报案。</p>
                <p>Step 2:请填妥并递交理赔申请表及有关证明文件</p>
                <p>Step 3:保险公司将及时处理索赔并于十个工作日内给您答复. </p>
                <p>附注:因护士上门服务导致患者出现的身体伤害, 须患者举证证明该次事故属于医疗过失责任或者法院裁定医疗责任,证明可由当地医卫管理机关或者第三方医疗鉴定出具报告</p>

                <div class="services_mg">附:护士职业责任保险详细条款</div>
                <div class="services_mg" style="text-align:center">总则</div>
                <div class="services_mg">第一条&ensp;本保险合同由保险条款、投保单、保险单、保险凭证以及批单组成。凡涉及本保险合同的约定，均应采用书面形式。</div>
                <div class="services_mg">第二条&ensp;凡在中国境内（不包括港澳台地区）依法设立，符合保险人承保对象范围、提供专业服务的个人、公司、组织、机构可作为本保险合同的被保险人。</div>
                <p>被保险人的雇员在其履行被保险人的专业服务时是本保险合同的被保险人。</p>
                <div class="services_mg" style="text-align:center">保险责任</div>
                <div class="services_mg">第三条&ensp;由于被保险人提供的保险单载明的专业服务存在过失、错误或遗漏，或被保险人因过失未能提供专业服务，造成委托人或其利害关系人的损失，委托人或其利害关系人在保险期间内首次向被保险人提出经济损失赔偿请求的，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。但保险人承担责任的前提条件是该引致赔偿请求的专业服务是（或应当是）：</div>
                <p>1、根据被保险人与委托人在保险期间或追溯期内签订的业务合同而提供；</p>
                <p>2、在保险单载明的保险期间或追溯期内完成；且</p>
                <p>3、在保险单载明的地域范围内提供。</p>
                <div class="services_mg">第四条&ensp;保险事故发生后，被保险人因保险事故而被提起仲裁或者诉讼的，对应由被保险人支付的仲裁或诉讼费用以及事先经保险人书面同意支付的其它必要的、合理的费用（以下简称“法律费用”），保险人按照本保险合同约定也负责赔偿。</div>
                <div class="services_mg" style="text-align:center">责任免除</div>
                <div class="services_mg">第五条&ensp;出现下列任一情形时，保险人不负责赔偿：</div>
                <p>（一）被保险人从事的业务超出其执业许可证或营业执照核定的范围；</p>
                <p>（二）被保险人超越委托人的授权范围所办理的业务；</p>
                <p>（三）被保险人在执业许可证、营业执照被注销、撤回、吊销期间，或其雇员在被取消执业资格或受停业、停职处分的情况下承办的业务；</p>
                <p>（四）被保险人的雇员未经被保险人同意私自承办的业务，或在受被保险人雇佣之前所承办的业务；</p>
                <p>（五）被保险人从事的非保险单载明的业务；</p>
                <p>（六）在保险单规定的追溯期起始日之前被保险人已经完成的专业服务。</p>
                <div class="services_mg">第六条&ensp;下列原因造成的损失、费用和责任，保险人不负责赔偿：</div>
                <p>（一）投保人、被保险人及其代表、雇员的故意行为、犯罪行为、重大过失、欺诈或不诚实行为、恶意串通损害委托人或其利害关系人利益的行为；</p>
                <p>（二）战争、敌对行动、军事行为、武装冲突、罢工、骚乱、暴动、恐怖活动；</p>
                <p>（三）核辐射、核爆炸、核污染及其他放射性污染；</p>
                <p>（四）大气污染、土地污染、水污染及其他各种污染；</p>
                <p>（五）行政行为或司法行为；</p>
                <p>（六）地震、火山爆发、海啸、雷击、洪水、暴雨、台风、龙卷风、暴风、雪灾、雹灾、冰凌、泥石流、崖崩、地崩、突发性滑坡、地面突然下陷等自然灾害；</p>
                <p>（七）委托人提供的账册、报表、文档、数据、资料、电子数据及其他性质类似文件的损毁、灭失、遭盗窃或抢劫、丢失；</p>
                <p>（八）被保险人违反书面或口头约定的保密义务；</p>
                <p>（九）被保险人泄露或不正当使用他人商业秘密，或侵犯他人知识产权、名誉权、隐私权；</p>
                <p>（十）被保险人的明示保证，但无该保证时仍然应由被保险人承担的赔偿责任不受此限；</p>
                <p>（十一）被保险人进行的任何担保。</p>
                <div class="services_mg">第七条&ensp;下列损失、费用和责任，保险人不负责赔偿：</div>
                <p>（一）被保险人或其雇员的人身伤亡及其所有或管理的财产的损失；</p>
                <p>（二）被保险人与他人签订的协议中约定的转嫁给被保险人来承担的、在无该协议的情况下本不应由被保险人承担的责任，但无该协议存在时仍然应由被保险人承担的经济赔偿责任不受此限；</p>
                <p>（三）罚款、罚金、惩罚性赔偿；</p>
                <p>（四）精神损害赔偿；</p>
                <p>（五）间接损失；</p>
                <p>（六）投保人、被保险人在投保之前已经知道或可以合理预见的赔偿请求或索赔的情况；</p>
                <p>（七）本保险合同中载明的免赔额。</p>
                <div class="services_mg">第八条&ensp;其他不属于本保险责任范围内的损失、费用和责任，保险人不负责赔偿。</div>
                <div class="services_mg" style="text-align:center">赔偿限额与免赔额</div>
                <div class="services_mg">第九条&ensp;赔偿限额包括每次事故赔偿限额、累计赔偿限额，由投保人与保险人协商确定，并在保险合同中载明。</div>
                <div class="services_mg">第十条&ensp;每次事故免赔额由投保人与保险人在签订保险合同时协商确定，并在保险合同中载明。</div>
                <div class="services_mg" style="text-align:center">保险期间</div>
                <div class="services_mg">第十一条&ensp;除另有约定外，保险期间为一年，以保险单载明的起讫时间为准。</div>
                <div class="services_mg" style="text-align:center">保险人义务</div>
                <div class="services_mg">第十二条&ensp;本保险合同成立后，保险人应当及时向投保人签发保险单或其他保险凭证。</div>
                <div class="services_mg">第十三条&ensp;保险人按照第二十四条的约定，认为被保险人提供的有关索赔的证明和资料不完整的，应当及时一次性通知投保人、被保险人补充提供。</div>
                <div class="services_mg">第十四条&ensp;保险人收到被保险人的赔偿保险金的请求后，应当及时作出是否属于保险责任的核定；情形复杂的，保险人将在确定是否属于保险责任的基本材料收集齐全后，尽快做出核定。</div>
                <p>保险人应当将核定结果通知被保险人；对属于保险责任的，在与被保险人达成赔偿保险金的协议后十日内，履行赔偿保险金义务。保险合同对赔偿保险金的期限有约定的，保险人应当按照约定履行赔偿保险金的义务。保险人依照前款的规定作出核定后，对不属于保险责任的，应当自作出核定之日起三日内向被保险人发出拒绝赔偿保险金通知书，并说明理由。</p>
                <div class="services_mg">第十五条&ensp;保险人自收到赔偿保险金的请求和有关证明、资料之日起六十日内，对其赔偿保险金的数额不能确定的，应当根据已有证明和资料可以确定的数额先予支付；保险人最终确定赔偿的数额后，应当支付相应的差额。</div>
                <div class="services_mg" style="text-align:center">投保人、被保险人义务</div>
                <div class="services_mg">第十六条&ensp;订立保险合同，保险人就保险标的或者被保险人的有关情况提出询问的，投保人应当如实告知。</div>
                <p>投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险人决定是否同意承保或者提高保险费率的，保险人有权解除保险合同。</p>
                <p>前款规定的合同解除权，自保险人知道有解除事由之日起，超过三十日不行使而消灭。自合同成立之日起超过二年的，保险人不得解除合同；发生保险事故的，保险人应当承担赔偿保险金的责任。</p>
                <p>投保人故意不履行如实告知义务的，保险人对于合同解除前发生的保险事故，不承担赔偿保险金的责任，并不退还保险费。</p>
                <p>投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险人对于合同解除前发生的保险事故，不承担赔偿保险金的责任，但应当退还保险费。</p>
                <p>保险人在合同订立时已经知道投保人未如实告知的情况的，保险人不得解除合同；发生保险事故的，保险人应当承担赔偿保险金的责任。</p>
                <div class="services_mg">第十七条&ensp;除另有约定外，投保人应当在保险合同成立时交付保险费。</div>
                <p>约定一次性交付保险费的，投保人在约定交费日后交付保险费的，保险人对交费之前发生的保险事故不承担保险责任。</p>
                <p>约定分期交付保险费的，保险人按照保险事故发生前保险人实际收取保险费总额与投保人应当交付的保险费的比例承担保险责任，投保人应当交付的保险费是指截至保险事故发生时投保人按约定分期应该缴纳的保费总额。</p>
                <div class="services_mg">第十八条&ensp;被保险人应严格遵守国家有关规定，加强管理，采取合理的预防措施，尽力避免或减少责任事故的发生。</div>
                <p>保险人可以对被保险人遵守前款约定的情况进行检查，向投保人、被保险人提出消除不安全因素和隐患的书面建议，投保人、被保险人应该认真付诸实施。但前述检查并不构成保险人对被保险人的任何承诺。</p>
                <p>投保人、被保险人未按照约定履行上述安全义务的，保险人有权要求增加保险费或者解除合同。</p>
                <div class="services_mg">第十九条&ensp;在保险合同有效期内，保险标的的危险程度显著增加的，被保险人应当及时通知保险人，保险人可以根据费率表的规定增加保险费或者解除合同。</div>
                <p>被保险人未履行前款约定的通知义务的，因保险标的的危险程度显著增加而发生的保险事故，保险人不承担赔偿保险金的责任。</p>
                <div class="services_mg">第二十条&ensp;如果保险人提出要求，投保人应在投保时将被保险人正式聘用的提供专业服务的雇员名单提交保险人。如果保险人要求，投保人还应提交能证明雇员具有执业资格的证明文件。</div>
                <p>在保险期间内，被保险人的雇员发生变动，投保人、被保险人应自变动之日起三个月之内将人员变动的名单提供给保险人。</p>
                <p>被保险人未履行本条所规定的义务，因保险标的危险程度显著增加而发生的保险事故，保险人不承担赔偿责任。</p>
                <div class="services_mg">第二十一条&ensp;知道保险事故发生后，被保险人应该：</div>
                <p>（一）尽力采取必要、合理的措施，防止或减少损失，否则，对因此扩大的损失，保险人不承担赔偿责任；</p>
                <p>（二）及时通知保险人，并书面说明事故发生的原因、经过和损失情况；故意或者因重大过失未及时通知，致使保险事故的性质、原因、损失程度等难以确定的，保险人对无法确定的部分，不承担赔偿责任，但保险人通过其他途径已经及时知道或者应当及时知道保险事故发生的除外；</p>
                <p>（三）保护事故现场，允许并且协助保险人进行事故调查；对于拒绝或者妨碍保险人进行事故调查导致无法确定事故原因或核实损失情况的，保险人对无法确定或核实的部分，不承担赔偿责任；</p>
                <p>（四）涉及违法、犯罪的，应立即向公安部门报案，否则，对因此扩大的损失，保险人不承担赔偿责任。</p>
                <div class="services_mg">第二十二条&ensp;被保险人收到委托人或其利害关系人的损害赔偿请求时，应立即通知保险人。未经保险人书面同意，被保险人对受害人作出的任何承诺、拒绝、出价、约定、付款或赔偿，保险人不受其约束。对于被保险人自行承诺或支付的赔偿金额，保险人有权重新核定，不属于本保险责任范围或超出应赔偿限额的，保险人不承担赔偿责任。在处理索赔过程中，保险人有权自行处理由其承担最终赔偿责任的任何索赔案件，被保险人有义务向保险人提供其所能提供的资料和协助。</div>
                <div class="services_mg">第二十三条&ensp;被保险人获悉可能发生诉讼、仲裁时，应立即以书面形式通知保险人；接到法院传票或其他法律文书后，应将其副本及时送交保险人。保险人有权以被保险人的名义处理有关诉讼或仲裁事宜，被保险人应提供有关文件，并给予必要的协助。</div>
                <p>对因未及时提供上述通知或必要协助导致扩大的损失，保险人不承担赔偿责任。</p>
                <div class="services_mg">第二十四条&ensp;被保险人请求赔偿时，应向保险人提供下列证明和资料：</div>
                <p>（一）保险单正本；</p>
                <p>（二）被保险人或其代表填具的索赔申请书；</p>
                <p>（三）引起赔偿请求的雇员与被保险人签订的劳动合同，以及能证明其具有执业资格的证明文件；</p>
                <p>（四）被保险人的执业许可证副本及有关文件；</p>
                <p>（五）被保险人与委托人签订的与赔偿请求有关的业务合同；</p>
                <p>（六）被保险人与委托人或其利害关系人所签订的赔偿协议书或和解书；经判决或仲裁的，应提供判决文书或仲裁裁决文书；</p>
                <p>（七）投保人、被保险人所能提供的与确认保险事故的性质、原因、损失程度等有关的其他证明和资料。</p>
                <p>被保险人未履行前款约定的索赔材料提供义务，导致保险人无法核实损失情况的，保险人对无法核实部分不承担赔偿责任。</p>
                <div class="services_mg" style="text-align:center">赔偿处理</div>
                <div class="services_mg">第二十五条&ensp;保险人的赔偿以下列方式之一确定的被保险人的赔偿责任为基础：</div>
                <p>（一）被保险人和向其提出损害赔偿请求的受害人协商并经保险人确认；</p>
                <p>（二）仲裁机构裁决；</p>
                <p>（三）人民法院判决；</p>
                <p>（四）保险人认可的其它方式。</p>
                <div class="services_mg">第二十六条&ensp;被保险人给第三者造成损害，被保险人未向该第三者赔偿的，保险人不负责向被保险人赔偿保险金。</div>
                <div class="services_mg">第二十七条&ensp;发生保险责任范围内的损失，保险人按以下方式计算赔偿：</div>
                <p>（一）对于每次事故造成的损失，保险人在每次事故赔偿限额内计算赔偿，其中对每次事故承担的法律费用的赔偿金额不超过每次事故赔偿限额的10%，但合同另有约定的除外。</p>
                <p>由同一原因引起的一个或一系列针对被保险人的赔偿请求将被视作为一次事故，第一个赔偿请求提出的日期将被视为该系列赔偿请求提出日期。</p>
                <p>（二）在依据本条第（一）项计算的基础上，保险人在扣除每次事故免赔额后进行赔偿；</p>
                <p>（三）在保险期间内，保险人对多次事故承担的本条款第三、四条规定的赔偿金额之和累计不超过累计赔偿限额。</p>
                <div class="services_mg">第二十八条&ensp;发生保险事故时，如果被保险人的损失在有相同保障的其他保险项下也能够获得赔偿，则本保险人按照本保险合同的赔偿限额与其他保险合同及本保险合同的赔偿限额总和的比例承担赔偿责任。</div>
                <p>其他保险人应承担的赔偿金额，本保险人不负责垫付。若被保险人未如实告知导致保险人多支付赔偿金的，保险人有权向被保险人追回多支付的部分。</p>
                <div class="services_mg">第二十九条&ensp;发生保险责任范围内的损失，应由有关责任方负责赔偿的，保险人自向被保险人赔偿保险金之日起，在赔偿金额范围内代位行使被保险人对有关责任方请求赔偿的权利，被保险人应当向保险人提供必要的文件和所知道的有关情况。</div>
                <p>被保险人已经从有关责任方取得赔偿的，保险人赔偿保险金时，可以相应扣减被保险人已从有关责任方取得的赔偿金额。</p>
                <p>保险事故发生后，在保险人未赔偿保险金之前，被保险人放弃对有关责任方请求赔偿权利的，保险人不承担赔偿责任；保险人向被保险人赔偿保险金后，被保险人未经保险人同意放弃对有关责任方请求赔偿权利的，该行为无效；由于被保险人故意或者因重大过失致使保险人不能行使代位请求赔偿的权利的，保险人可以扣减或者要求返还相应的保险金。</p>
                <div class="services_mg">第三十条&ensp;保险人受理报案、进行现场查勘、核损定价、参与案件诉讼、向被保险人提供建议等行为，均不构成保险人对赔偿责任的承诺。</div>
                <div class="services_mg">第三十一条&ensp;被保险人向保险人请求赔偿保险金的诉讼时效期间为二年，自其知道或者应当知道保险事故发生之日起计算。</div>
                <div class="services_mg" style="text-align:center">争议处理和法律适用</div>
                <div class="services_mg">第三十二条&ensp;因履行本保险合同发生的争议，由当事人协商解决。协商不成的，提交保险单载明的仲裁机构仲裁；保险单未载明仲裁机构且争议发生后未达成仲裁协议的，依法向中华人民共和国人民法院起诉。</div>
                <div class="services_mg">第三十三条&ensp;本保险合同的争议处理适用中华人民共和国法律（不包括港澳台地区法律）。</div>
                <div class="services_mg" style="text-align:center">其他事项</div>
                <div class="services_mg">第三十四条&ensp;投保人和保险人可以协商变更合同内容。</div>
                <p>变更保险合同的，应当由保险人在保险单或者其他保险凭证上批注或附贴批单，或者投保人和保险人订立变更的书面协议。</p>
                <div class="services_mg">第三十五条&ensp;投保人可随时书面申请解除本保险合同，本保险合同自保险人收到投保人的书面申请之日的二十四时起终止。保险责任开始前，投保人要求解除合同的，保险人扣除3%手续费后，剩余部分的保险费退还投保人；保险责任开始后，投保人要求解除合同的，对保险责任开始之日起至合同解除之日止期间的保险费，按短期费率计收，剩余部分退还投保人。</div>
                <p>保险人亦可解除本保险合同。保险责任开始前，保险人要求解除合同的，不得向投保人收取手续费并应退还已收取的保险费；保险责任开始后，保险人可提前十五天通知投保人解除合同，对保险责任开始之日起至合同解除之日止期间的保险费，按日比例计收，剩余部分退还投保人。</p>
                <div class="services_mg">第三十六条&ensp;发生保险事故且保险人已承担赔偿责任的，自保险人赔偿之日起三十日内，投保人可以解除合同；除合同另有约定外，保险人也可以解除合同，但应当提前十五日通知投保人。</div>
                <p>保险合同依据前款规定解除的，保险人应当将累计赔偿限额扣除累计已赔偿金额后剩余部分的保险费，按照合同约定扣除自保险责任开始之日起至合同解除之日止应收的部分后，退还投保人。</p>
                <div class="services_mg" style="text-align:center">释义</div>
                <div class="services_mg">【专业服务】本保险合同指要求特殊的教育、知识、判断、技能且以脑力劳动而非体力劳动为主的业务。被保险人向委托人提供或交付的研究、分析、鉴定、结论、报告、报表或其他专业文件等都属于专业服务的一部分。</div>

                <div class="services_mg">【雇员】是指与被保险人签订有劳动合同或存在事实劳动合同关系，接受被保险人给付薪金、工资，年满十六周岁的人员及其他按国家规定审批的未满十六周岁的特殊人员, 包括正式在册职工、短期工、临时工、季节工和徒工等。但因委托代理、行纪、居间等其他合同为被保险人提供服务或工作的人员不属于本保险合同所称雇员。</div>
                <div class="services_mg">【委托人】指与被保险人签订委托业务合同，委托被保险人为其办理业务的单位或个人。</div>
                <div class="services_mg">【利害关系人】指与被保险人和委托人签订的业务合同有利益关系者，包括但不限于委托人的投资人、债权人、与委托人有业务往来的客户，以及依法或依合同有权了解、使用被保险人提供的专业服务或依照该专业服务做出决策的人等。</div>
                <div class="services_mg">【追溯期】指从保险单载明的追溯日起至保险期间起始日止的期间。</div>
                <div class="services_mg">【间接损失】指由保险事故导致的财产本身的损失而间接引起的额外费用损失、收入损失和责任损失等。</div>
                <div class="services_mg">【故意】明知自己的行为会导致保险事故的发生，而希望和放任这种结果出现的一种心理状态。</div>
                <div class="services_mg">【商业秘密】指不为公众所知悉、能为权利人带来经济利益、具有实用性并经权利人采取保密措施的技术信息和经营信息。</div>
                <p>除以上释义外，对其他专业术语的理解，以相关法律法规的解释为准。</p>


                </div>
                </div>
            </van-tab>
        </van-tabs>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'


export default {
  name: 'insurance',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()



    return {
        router
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .content{
        margin-top:20px;
    }
    .services_block{
        padding:30px;
        padding-top:0;
        font-size: 20px;
        h3{
            font-size: 35px;
        }
    }

</style>
